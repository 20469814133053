<style lang="scss">
  .select-box{
    border:1px solid #d1d1d1;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius:4px;
    @apply text-center;
    color:#000;
    padding:20px;
    margin: 0 auto;
    width:330px;

    .sticker{
      position:absolute;
      top:-25px;
      left:-25px;
      background:#FAB413;
      border-radius:100%;
      padding:18px 12px;
      font-size:12px;
      font-weight:900;
      transform:rotate(-30deg);
      text-align:center;
    }

    .green-btn{
      padding-right:30px;
      padding-left:30px;
    }

    &.active{
      border: 2px solid #FAB413;
    }
    ul{
      text-align:left;
      font-size:16px;
      margin-left:45px;
      li{
        margin-bottom:10px;
      }
    }
  }
  .history{
    border:1px solid #D1D1D1;
    border-radius:4px;
    .header{
      background:#FBFBFB;
      padding:10px 15px;
      border-radius:4px 4px 0 0;
      border-bottom:1px solid #EDEDED;
    }
    .billing{
      display:flex;
      border-bottom:1px solid #EDEDED;
      padding:15px;
      &:last-child{
        border:none;
      }
      &.hidden{
        display:none;
      }
    }

    .referral-list{


      &:last-child{
        border:none;
      }

      .referral{
         padding:15px;
         display:flex;
         border-bottom:1px solid #EDEDED;

        &.hidden{
          display:none;
        }
      }
    }

    .showMore{
      border-top:1px solid #EDEDED;
      border-bottom:none;
    }
  }

  .custom-languages {
    .table-component {
      margin-bottom: 0;
    }

    .table-component__table-wrapper {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .custom-languages__footer {
      margin-top: 20px;
      border: 1px solid #d1d1d1;
      border-radius: 4px;
      padding: 23px 16px;
    }

    .table-component + .custom-languages__footer {
      border-top-color: #ededed;
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
</style>

<template>
  <div class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav short">
    <vue-headful
      title="Settings - Language | Octoa"
    />

    <div class="flex flex-wrap mb-5">
      <div class="w-full lg:w-1/4">
        <setting-menu />
      </div>

      <div class="w-full lg:w-3/4">
        <div
          v-if="loading"
          class="text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32"
          >
        </div>
        <div
          v-else
          class="pb-20"
        >
          <div class="bg-white h-full mx-4 my-4 px-4 py-4 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded">
            <h2 class="font-bold mb-5">
              Supported languages
            </h2>

            <p class="leading-loosen mb-6">
              These are the languages that are supported by the tool. This means that everything that is sent out to the client will be shown in that specific language. Language can be chosen when you create a new project.
            </p>

            <languages-table
              :languages="supportedLanguages"
              :defaultLanguage="defaultLanguage"
              :activeLanguages="activeLanguages"
              @onDefaultChange="handleDefaultLanguageChange"
              @onActiveChange="handleActiveLanguageChange"
            />

            <h2 class="font-bold mb-5">
              Custom language
            </h2>
            <p class="leading-loosen">
              You can add a custom language to the tool. This means that it gives you the option to create email templates in that specific language. Keep in mind this is only for personal use. The tool does not support this language when things are sent out. You can always sent us a language request.
            </p>

            <div class="custom-languages">
              <languages-table
                v-if="customLanguages.length"
                :languages="customLanguages"
                :defaultLanguage="defaultLanguage"
                :activeLanguages="customLanguages.filter(l => l.isActive).map(l => l.code)"
                @onDefaultChange="handleDefaultLanguageChange"
                @onActiveChange="handleActiveLanguageChange"
                :canDelete="true"
                @delete="handleDeleteCustomLanguage"
              />

              <div class="custom-languages__footer">
                <div v-if="isAddingCustomLanguage" class="mb-5">
                  <div class="select-container">
                    <select
                      v-model="newCustomLanguage"
                      v-validate="'required'"
                      name="new-custom-language"
                    >
                      <option value="">Select custom language</option>
                      <template v-for="(languageLabel, languageCode) in availableCustomLanguages">
                        <option :value="languageCode">
                          {{ languageLabel }}
                        </option>
                      </template>
                    </select>
                  </div>

                  <button @click.prevent="handleAddCustomLanguage" class="ml-4">Add Language</button>
                </div>

                <a href="#" @click.prevent="isAddingCustomLanguage = false" class="block underline text-red hover:text-red-darker" v-if="isAddingCustomLanguage">discard adding custom language</a>
                <a href="#" @click.prevent="isAddingCustomLanguage = true" class="block underline green" v-else>+ add custom language</a>
              </div>
            </div>
          </div>
          <div class="w-full px-4 mt-4 lg:px-0 mb-20 text-right">
            <v-button
              v-if="!saving"
              @click="save"
            >
              Save
            </v-button>
            <div
              v-else
              class="text-right"
            >
              <img
                src="@/assets/img/icons/loader.svg"
                width="35"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * External dependencies
 */
import filter from 'lodash.filter';
import pickBy from 'lodash.pickby';

/**
 * Internal dependencies
 */
import auth from '@/auth';
import config from '@/config';
import { getAvailableCustomLanguages } from '@/locales';

export default {
  name: 'SettingsLanguage',
  data(){
    return {
      loading: true,
      saving: false,
      user: auth.user(),
      companyLanguage: 'en',
      defaultLanguage: null,
      customLanguages: [],
      newCustomLanguage: '',
      isAddingCustomLanguage: false
    }
  },
  computed: {
    company() {
      return this.$store.getters['company/details'];
    },

    supportedLanguages() {
      return filter(this.company.locales, (locale => locale.isSupported));
    },

    activeLanguages() {
      return Object.values(this.company.locales)
        .filter(locale => locale.isActive)
        .map(locale => locale.code);
    },

    /**
     * Contains all available languages.
     *
     * @return {Object}
     */
    allLanguages() {
      return getAvailableCustomLanguages();
    },

    /**
     * Contains the languages available for custom addition.
     *
     * @return {Object}
     */
    availableCustomLanguages() {
      return pickBy(this.allLanguages, (languageLabel, languageCode) => {
        return ! this.supportedLanguages.find(lang => lang.code === languageCode)
          && ! this.customLanguages.find(lang => lang.code === languageCode);
      });
    },

    defaultLanguageData() {
      return this.supportedLanguages.find(lang => lang.code === this.defaultLanguage)
        || this.customLanguages.find(lang => lang.code === this.defaultLanguage);
    }
  },
  async mounted(){
     await this.refresh()
     this.loading = false
  },
  methods: {
    /**
     * Handles deletion of a custom language.
     *
     * @param  {String} code
     * @return {void}
     */
    async handleDeleteCustomLanguage(code) {
      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete?',
        text: 'If you delete this language, you will lose it\'s content.',
      })

      if (canDelete) {
       try {
          await this.$api.get('company').destroyLanguage(this.company.id, code);

          this.customLanguages = this.customLanguages.filter((language) => {
            return language.code !== code;
          });

          this.save();
        } catch(e){
          logException(e)
        }
      }
    },

    handleAddCustomLanguage() {
      if (! this.newCustomLanguage) {
        return;
      }

      this.customLanguages.push({
        'code': this.newCustomLanguage,
        'label': this.allLanguages[this.newCustomLanguage],
        'isDefault': false,
        'isActive': true,
        'isCustom': true,
      });

      this.activeLanguages.push(this.newCustomLanguage);
      this.isAddingCustomLanguage = false;
      this.newCustomLanguage = '';
    },

    async refresh() {
      try {
        await this.$store.dispatch('company/refreshCompanyDetails')

        const company = this.$store.getters['company/details']
        this.companyLanguage = company.defaultLanguage
        this.defaultLanguage = company.defaultLanguage;
        this.customLanguages = company.customLocales.map(locale => ({
          'code': locale,
          'label': this.allLanguages[locale],
          'isDefault': false,
          'isActive': this.activeLanguages.indexOf(locale) !== -1,
          'isCustom': true,
        }));

        this.defaultLanguageData.isDefault = true;

        auth.refreshUser()

      } catch(e) {
        this.$toasted.global.api_error(e)
      }
    },
    async save() {
      this.saving = true
      this.$toasted.clear()

      const postData = {
        defaultLanguage: this.defaultLanguage,
        activeLanguages: [...this.activeLanguages, ...this.customLanguages.filter(locale => locale.isActive).map(locale => locale.code) ],
        customLanguages: this.customLanguages.map(locale => locale.code),
      }

      try {
        await this.$store.dispatch('company/patchLanguage', postData)
        this.$toasted.global.general_success({
          message : 'Settings updated.'
        })
      } catch(e) {
        this.$toasted.global.api_error(e)
      }
      this.saving = false
      await this.refresh()
    },

    /**
     * Handles the default language update.
     *
     * @param {String} locale
     * @return {void}
     */
    handleDefaultLanguageChange(locale) {
      this.defaultLanguage = locale;

      const language = this.supportedLanguages.find(lang => lang.code === locale)
        || this.customLanguages.find(lang => lang.code === locale);

      this.supportedLanguages.forEach((lang) => {
        lang.isDefault = false;
      });

      this.customLanguages.forEach((lang) => {
        lang.isDefault = false;
      });

      this.defaultLanguageData.isDefault = true;
    },

    /**
     * Handles the active language update.
     *
     * @param {String} locale
     * @return {void}
     */
    handleActiveLanguageChange(locale) {
      const customLanguage = this.customLanguages.find(language => language.code === locale);
      const supportedLanguage = this.supportedLanguages.find(language => language.code === locale);

      if (this.activeLanguages.indexOf(locale) === -1) {
        this.activeLanguages.push(locale);

        if (customLanguage) {
          customLanguage.isActive = true;
        }

        if (supportedLanguage) {
          supportedLanguage.isActive = true;
        }
      } else {
        this.activeLanguages.splice(this.activeLanguages.indexOf(locale), 1);

        if (customLanguage) {
          customLanguage.isActive = false;
        }

        if (supportedLanguage) {
          supportedLanguage.isActive = false;
        }
      }
    }
  },
}
</script>
